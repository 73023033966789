import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./youtube_component.scss";

import { styleToObjResponsive, getText } from "../../utils/functions";

const TextComponent = (props) => {
  const { index, data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  const iframeURL = data.youtube && data.youtube.url ? data.youtube.url : "";
  const allow = data.youtube && data.youtube.allow ? data.youtube.url : "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
  const title = getText(data, `title.text.${lang}`, "");
  const width = data.youtube && data.youtube.width ? data.youtube.width : "1014";
  const height = data.youtube && data.youtube.height ? data.youtube.height : "572";

  return (
    <div className={`component-youtube_component component`}
      data-type="component"
      data-index={index}
      draggable={true}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      {title !== "" && <div style={styleToObjResponsive(data.title.style, data.title.style_desktop, desktop)} className="text-title" >
        {getText(data, `title.text.${lang}`, "")}
      </div>}

      <div className="iframe-wrapper">
        <iframe src={iframeURL}
          title="YouTube video player"
          frameBorder="0"
          allow={allow}
          width={width}
          height={height}
          style={styleToObjResponsive(data.youtube.style, data.youtube.style_desktop, desktop)}
          allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default TextComponent;