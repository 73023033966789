import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./nav_bar_1.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";

const NavBarComponent = (props) => {
  const { data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  let wrapperStyle = styleToObjResponsive(data.style, data.style_desktop, desktop);
  if (desktop && (data.item_1_color || data.item_3_color)) wrapperStyle.background =
    `linear-gradient(to right, ${data.item_1_color ? data.item_1_color : '#FF9900'} 0%, ${data.item_1_color ? data.item_1_color : '#FF9900'} 50%, ${data.item_3_color ? data.item_3_color : '#FF7000'} 50%, ${data.item_3_color ? data.item_3_color : '#FF7000'} 100%)`;

  let item1BgColor = { background: "#ff9900" };
  let item2BgColor = { background: "#ff7f00" };
  let item3BgColor = { background: "#ff7000" };
  if (data.item_1_color) item1BgColor.background = data.item_1_color;
  if (data.item_2_color) item2BgColor.background = data.item_2_color;
  if (data.item_3_color) item3BgColor.background = data.item_3_color;

  let item1BrdColor = { borderColor: !desktop ? "#FF9900 transparent transparent transparent" : "transparent transparent transparent #FF9900" };
  if (data.item_1_color) item1BrdColor.borderColor = !desktop ? `${data.item_1_color} transparent transparent transparent` : `transparent transparent transparent ${data.item_1_color}`;

  let item2BrdColor = { borderColor: !desktop ? "#FF7F00 transparent transparent transparent" : "transparent transparent transparent #FF7F00" };
  if (data.item_2_color) item2BrdColor.borderColor = !desktop ? `${data.item_2_color} transparent transparent transparent` : `transparent transparent transparent ${data.item_2_color}`;

  let item3BrdColor = { borderColor: !desktop ? "#ff7000 transparent transparent transparent" : "transparent transparent transparent #ff7000" };
  if (data.item_3_color) item3BrdColor.borderColor = !desktop ? `${data.item_3_color} transparent transparent transparent` : `transparent transparent transparent ${data.item_3_color}`;

  return (
    <div className={`component-nav_bar_1 component`}
      style={wrapperStyle}
    >
      <div className="navItem first" style={item1BgColor}>
        <div className="navItemContent">
          <div className="number">
            <span>1</span>
          </div>
          <div className="description">
            <h3 className="navTitle" style={styleToObjResponsive(data.item_1_title.style, data.item_1_title.style_desktop, desktop)}>{getText(data, `item_1_title.text.${lang}`, "")}</h3>
            <p className="navSubTitle" style={styleToObjResponsive(data.item_1_subtitle.style, data.item_1_subtitle.style_desktop, desktop)}>{getText(data, `item_1_subtitle.text.${lang}`, "")}</p>
          </div>
        </div>
      </div>
      <div className="navItem second" style={item2BgColor}>
        <div className="before" style={item1BrdColor}></div>
        <div className="navItemContent">
          <div className="number">
            <span>2</span>
          </div>
          <div className="description">
            <h3 className="navTitle" style={styleToObjResponsive(data.item_2_title.style, data.item_2_title.style_desktop, desktop)}>{getText(data, `item_2_title.text.${lang}`, "")}</h3>
            <p className="navSubTitle" style={styleToObjResponsive(data.item_2_subtitle.style, data.item_2_subtitle.style_desktop, desktop)}>{getText(data, `item_2_subtitle.text.${lang}`, "")}</p>
          </div>
        </div>
      </div>
      <div className="navItem third" style={item3BgColor}>
        <div className="before" style={item2BrdColor}></div>
        <div className="navItemContent">
          <div className="number">
            <span>3</span>
          </div>
          <div className="description">
            <h3 className="navTitle" style={styleToObjResponsive(data.item_3_title.style, data.item_3_title.style_desktop, desktop)}>{getText(data, `item_3_title.text.${lang}`, "")}</h3>
            <p className="navSubTitle" style={styleToObjResponsive(data.item_3_subtitle.style, data.item_3_subtitle.style_desktop, desktop)}>{getText(data, `item_3_subtitle.text.${lang}`, "")}</p>
          </div>
        </div>
      </div>
      <div className="lastArrow">
        <div className="before" style={item3BrdColor}></div>
      </div>
    </div>
  );
}

export default NavBarComponent;