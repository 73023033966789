import { appConstants } from "../constants";

export const appInitialize = () => ({
	type: appConstants.INITIALIZE
});

export const appReceivedConfig = (config) => ({
	type: appConstants.RECEIVED_CONFIG,
	config
});

export const appReloadConfig = (config) => ({
	type: appConstants.RELOAD_CONFIG,
});

