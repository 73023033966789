import React from 'react';
import { clientId } from "../init";

const Home = (props) => {
  React.useEffect(() => {
    if (window.ini.CLIENTS_DOMAIN?.[clientId]) {
      window.location.assign(`https://${window.ini.CLIENTS_DOMAIN?.[clientId]}/`);
    }
  });

  return (
    <div>Redirecting ...</div>
  );
}

export default Home;