import { takeEvery } from "redux-saga/effects";
import openSocket from "socket.io-client";

import getStore from "../../store";
import { appConstants } from "../constants";
import { appReloadConfig } from "../actions/app";
import endpoints from "../../api/endpoints";
import { clientId } from "../../init";

let socket = null;

export const connect = () => {
	if (socket !== null) {
		return;
	}

	const query = {
		protocol: "sio1",
		tenantId: clientId
	};

	socket = openSocket(endpoints.ws, {
		path: endpoints.ws_path,
		forceNew: true,
		transports: ["websocket"],
		allowEIO3: true,
		query
	});

	socket.on("connect", () => { });

	socket.on("event", (data) => { });

	socket.on("error", error => { });

	socket.on("connect_error", error => { });

	socket.on("reload_landing_page_config", data => {
		getStore().dispatch(appReloadConfig());
	});
};

export const disconnect = () => {
	if (socket !== null) {
		socket.disconnect();
		socket = null;
	}
};


function* appInitializeSaga() {
	yield connect();
}

export default function* watchWS() {
	yield takeEvery(appConstants.INITIALIZE, appInitializeSaga);
}
