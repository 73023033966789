import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./main_banner_2.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";
import DefaultLogo from "./assets/logo.png";

const emptyHash = {};

const MainBannerComponent = (props) => {
  const { data, lang, form, setForm } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  let logo = DefaultLogo;
  if (!desktop && data.logo.image) {
    logo = data.logo.image;
  }
  if (desktop && data.logo.desktop_image) {
    logo = data.logo.desktop_image;
  }

  const bgStyle = styleToObjResponsive(data.bg_image.style, data.bg_image.style_desktop, desktop);
  if (!desktop && data.bg_image.image) {
    bgStyle.backgroundImage = `url("${data.bg_image.image}")`;
  }
  if (desktop && data.bg_image.desktop_image) {
    bgStyle.backgroundImage = `url("${data.bg_image.desktop_image}")`;
  }

  let item1BgColor = { fill: "#ff9900" };
  let item2BgColor = { fill: "#ff7f00" };
  let item3BgColor = { fill: "#ff7000" };
  if (data.item_1_color) item1BgColor.fill = data.item_1_color;
  if (data.item_2_color) item2BgColor.fill = data.item_2_color;
  if (data.item_3_color) item3BgColor.fill = data.item_3_color;

  let points = "0,0 174,30 347,0 347,88 174,118 0,88";
  if (desktop) points = "0,0 317,0 347,59 317,118 0,118 30,59";

  const handleForm = (e) => {
    const newForm = { ...form };
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  };

  const show_name = typeof data.banner_wrapper.show_name !== "undefined" ? data.banner_wrapper.show_name : true;
  const show_phone = typeof data.banner_wrapper.show_phone !== "undefined" ? data.banner_wrapper.show_phone : true;
  const show_email = typeof data.banner_wrapper.show_email !== "undefined" ? data.banner_wrapper.show_email : false;
  const is_email_yahoo = typeof data.banner_wrapper.is_email_yahoo !== "undefined" ? data.banner_wrapper.is_email_yahoo : false;

  return (
    <div className={`component-main_banner_2 component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <div className="after" style={bgStyle}></div>
      <div className="bannerContent">
        <div className={`topBanner ${data.banner_wrapper.align === 2 ? 'place-center' : ''} ${data.banner_wrapper.align === 3 ? 'place-right' : ''}`}>
          <div className="header">
            <a href={data.logo.url} className="logo">
              <img src={logo} style={styleToObjResponsive(data.logo.style, data.logo.style_desktop, desktop)} alt="Winner" className="logoImg" />
            </a>
            <div className="topButtons">
              <a href={data.register_button.url} style={styleToObjResponsive(data.register_button.style, data.register_button.style_desktop, desktop)} className="button primary">{getText(data, `register_button.text.${lang}`, "NOT DEFINED")}</a>
              <a href={data.account_button.url} style={styleToObjResponsive(data.account_button.style, data.account_button.style_desktop, desktop)} className="button secondary">{getText(data, `account_button.text.${lang}`, "NOT DEFINED")}</a>
            </div>
          </div>

          {(data.banner_wrapper.type === 1 || !data.banner_wrapper.type) && <div className="form-wrapper wrapper-1" style={styleToObjResponsive(data.banner_wrapper.style, data.banner_wrapper.style_desktop, desktop)}>
            <div className="rich-text" style={styleToObjResponsive(data.banner.style, data.banner.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `banner.text.${lang}`, "NOT DEFINED") }}></div>
            <a href={data.cta_button.url} style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)} className="button gradient">{getText(data, `cta_button.text.${lang}`, "NOT DEFINED")}</a>
          </div>}

          {data.banner_wrapper.type === 2 && <div className="wrapper-2">
            <div className="rich-text" style={styleToObjResponsive(data.banner.style, data.banner.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `banner.text.${lang}`, "NOT DEFINED") }}></div>
            <div className="form-wrapper" style={styleToObjResponsive(data.banner_wrapper.style, data.banner_wrapper.style_desktop, desktop)}>
              <div className="inputs">
                {show_name && <div className="input">
                  <input placeholder='Nume' value={form.name} name="name" onChange={handleForm} />
                </div>}
                {show_phone && <div className="input">
                  <input placeholder='Numar de telefon' value={form.phone} name="phone" onChange={handleForm} />
                </div>}
                {show_email && <div className="input">
                  <input placeholder='Adresa de email' value={is_email_yahoo ? form.emailYahoo : form.email} name={is_email_yahoo ? 'emailYahoo' : 'email'} onChange={handleForm} />
                </div>}
              </div>
              <a href={data.cta_button.url} style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)} className="button gradient">{getText(data, `cta_button.text.${lang}`, "NOT DEFINED")}</a>
            </div>
          </div>
          }

          {data.banner_wrapper.type === 3 && <div className="form-wrapper wrapper-3" style={styleToObjResponsive(data.banner_wrapper.style, data.banner_wrapper.style_desktop, desktop)}>
            <div className="rich-text" style={styleToObjResponsive(data.banner.style, data.banner.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `banner.text.${lang}`, "NOT DEFINED") }}></div>
            <div className="inputs">
              {show_name && <div className="input">
                <input placeholder='Nume' value={form.name} name="name" onChange={handleForm} />
              </div>}
              {show_phone && <div className="input">
                <input placeholder='Numar de telefon' value={form.phone} name="phone" onChange={handleForm} />
              </div>}
              {show_email && <div className="input">
                <input placeholder='Adresa de email' value={is_email_yahoo ? form.emailYahoo : form.email} name={is_email_yahoo ? 'emailYahoo' : 'email'} onChange={handleForm} />
              </div>}
            </div>
            <a href={data.cta_button.url} style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)} className="button gradient">{getText(data, `cta_button.text.${lang}`, "NOT DEFINED")}</a>
          </div>}
        </div>
      </div>

      <div className={`itemsWrapper ${data.items.type === 2 ? "type-2" : ""} ${data.items.type === 3 ? "type-3" : ""}`}>
        <div className="navItem first" style={data.item_1_style ? styleToObjResponsive(data.item_1_style.style, data.item_1_style.style_desktop, desktop) : emptyHash}>
          <svg viewBox="0 0 347 118">
            <polygon style={item1BgColor} points={points} />
          </svg>
          <div className="navItemContent">
            <div className="number">
              <span>1</span>
            </div>
            <div className="description">
              <h3 className="navTitle" style={styleToObjResponsive(data.item_1_title.style, data.item_1_title.style_desktop, desktop)}>{getText(data, `item_1_title.text.${lang}`, "NOT DEFINED")}</h3>
              <p className="navSubTitle" style={styleToObjResponsive(data.item_1_subtitle.style, data.item_1_subtitle.style_desktop, desktop)}>{getText(data, `item_1_subtitle.text.${lang}`, "NOT DEFINED")}</p>
            </div>
          </div>
        </div>
        <div className="navItem second" style={data.item_2_style ? styleToObjResponsive(data.item_2_style.style, data.item_2_style.style_desktop, desktop) : emptyHash}>
          <svg viewBox="0 0 347 118">
            <polygon style={item2BgColor} points={points} />
          </svg>
          <div className="navItemContent">
            <div className="number">
              <span>2</span>
            </div>
            <div className="description">
              <h3 className="navTitle" style={styleToObjResponsive(data.item_2_title.style, data.item_2_title.style_desktop, desktop)}>{getText(data, `item_2_title.text.${lang}`, "NOT DEFINED")}</h3>
              <p className="navSubTitle" style={styleToObjResponsive(data.item_2_subtitle.style, data.item_2_subtitle.style_desktop, desktop)}>{getText(data, `item_2_subtitle.text.${lang}`, "NOT DEFINED")}</p>
            </div>
          </div>
        </div>
        <div className="navItem third" style={data.item_3_style ? styleToObjResponsive(data.item_3_style.style, data.item_3_style.style_desktop, desktop) : emptyHash}>
          <svg viewBox="0 0 347 118">
            <polygon style={item3BgColor} points={points} />
          </svg>
          <div className="navItemContent">
            <div className="number">
              <span>3</span>
            </div>
            <div className="description">
              <h3 className="navTitle" style={styleToObjResponsive(data.item_3_title.style, data.item_3_title.style_desktop, desktop)}>{getText(data, `item_3_title.text.${lang}`, "NOT DEFINED")}</h3>
              <p className="navSubTitle" style={styleToObjResponsive(data.item_3_subtitle.style, data.item_3_subtitle.style_desktop, desktop)}>{getText(data, `item_3_subtitle.text.${lang}`, "NOT DEFINED")}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MainBannerComponent;