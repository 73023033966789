import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { checkComponentAvailability, styleToObj } from "../utils/functions";

import "./LandingPages.scss";

import Meta from "../components/meta/meta";
import MainBanner1Component from '../components/main-banner-1/main_banner_1';
import MainBanner2Component from '../components/main-banner-2/main_banner_2';
import WheelBannerComponent from '../components/wheel-banner/wheel_banner';
import NavBar1Component from '../components/nav-bar-1/nav_bar_1';
import RowBlocks1Component from '../components/row-blocks-1/row_blocks_1';
import GenericBlock1Component from '../components/generic-block-1/generic_block_1';
import TermsAndConditions1Component from "../components/terms-and-conditions-1/terms_and_conditions_1";
import CarouselComponent from "../components/carousel/carousel";
import FooterComponent from "../components/footer/footer";
import ShapeComponent from "../components/shape/shape";
import ImageComponent from "../components/image/image";
import YoutubeComponent from "../components/youtube_component/youtube_component";
import { clientId } from "../init";

const empty = {};

const interceptClickEvent = (data) => (e) => {
  let target = e.target || e.srcElement;

  if (target.tagName !== 'A') {
    let tmp = target.closest("A");
    if (tmp && tmp.tagName === 'A') {
      target = tmp;
    }
  }

  if (target.tagName === 'A') {
    let href = target.getAttribute('href');
    e.preventDefault();

    const search = window.location.search.replace("?", "");

    const params = [];
    if (data) {
      Object.keys(data).forEach(key => {
        if (key && data[key]) {
          params.push(`${key}=${encodeURIComponent(data[key])}`)
        }
      })
    }

    if (href.indexOf("?") > -1) {
      href = `${href}&${search ? search + "&" : ""}${params.join("&")}`;
    } else {
      href = `${href}?${search ? search + "&" : ""}${params.join("&")}`;
    }

    window.location.assign(href);
  }
};

const LadingPages = (props) => {
  const { landingPages, location } = props;

  const [pageData, setPageData] = React.useState(null);
  const lang = "ro";

  const [form, setForm] = React.useState({
    name: "",
    phone: "",
  });

  React.useEffect(() => {
    if (landingPages && Object.keys(landingPages).length > 0) {
      let path = location.pathname;
      if (path[path.length - 1] === "/") {
        path = path.slice(0, -1);
      }

      if (typeof landingPages[path] !== "undefined") {
        setPageData(landingPages[path]);
      } else {
        if (window.ini.CLIENTS_DOMAIN?.[clientId]) {
          window.location.assign(`https://${window.ini.CLIENTS_DOMAIN?.[clientId]}/`);
        }
      }
    }
  }, [landingPages, location]);

  React.useEffect(() => {
    let interceptClickEventCB = null;
    if (pageData) {
      const data = {
        moid: pageData && pageData.settings && pageData.settings.moid ? pageData.settings.moid : "",
        lpid: pageData && pageData.lpid ? pageData.lpid : "",
        name: form && form.name ? form.name : "",
        phone: form && form.phone ? form.phone : "",
        email: form && form.email ? form.email : "",
        emailYahoo: form && form.emailYahoo ? form.emailYahoo : "",
      }

      interceptClickEventCB = interceptClickEvent(data);
      document.addEventListener("click", interceptClickEventCB, false);
    }
    return () => {
      if (interceptClickEventCB) document.removeEventListener("click", interceptClickEventCB, false);
    };
  }, [pageData, form]);

  if (!pageData) return null;

  const components = [];
  let pageStyle = empty;

  if (typeof pageData.meta !== "undefined") {

    const settings = pageData.settings ? pageData.settings : null;
    if (settings && settings.meta && Object.keys(settings.meta).length > 0) {
      components.push(<Meta key="meta" meta={settings.meta} />);
    }

    pageData.meta.forEach((data, index) => {
      if (!checkComponentAvailability(data)) return;

      if (data.type === "main_banner_1") {
        components.push(<MainBanner1Component key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "main_banner_2") {
        components.push(<MainBanner2Component key={index} index={index} data={data} lang={lang} form={form} setForm={setForm} />);
      } else if (data.type === "wheel_banner") {
        components.push(<WheelBannerComponent key={index} index={index} data={data} lang={lang} form={form} setForm={setForm} />);
      } else if (data.type === "nav_bar_1") {
        components.push(<NavBar1Component key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "row_blocks_1") {
        components.push(<RowBlocks1Component key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "generic_block_1") {
        components.push(<GenericBlock1Component key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "terms_and_conditions_1") {
        components.push(<TermsAndConditions1Component key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "carousel") {
        components.push(<CarouselComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "footer") {
        components.push(<FooterComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "shape") {
        components.push(<ShapeComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "image") {
        components.push(<ImageComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "youtube_component") {
        components.push(<YoutubeComponent key={index} index={index} data={data} lang={lang} />);
      }
    });

    pageStyle = styleToObj(pageData.settings && pageData.settings.style ? pageData.settings.style : empty);
  }

  return (
    <div className="page-wrapper" style={pageStyle}>
      {components}
    </div>
  );
}
const nullRef = null;
const mapProps = (state, props) => {
  return {
    landingPages: state.lp && state.lp.app && state.lp.app.landingPages ? state.lp.app.landingPages : nullRef
  }
}

export default withRouter(connect(mapProps)(LadingPages));