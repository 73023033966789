import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./row_blocks_1.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";

const empty = {};

const RowBlocksComponent = (props) => {
  const { data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  const stxt1 = getText(data, `item_1_subtext.text.${lang}`, "");
  const stxt2 = getText(data, `item_2_subtext.text.${lang}`, "");
  const stxt3 = getText(data, `item_3_subtext.text.${lang}`, "");
  const stxt4 = getText(data, `item_4_subtext.text.${lang}`, "");

  return (
    <div className={`component-row_blocks_1 component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <div className={`row_blocks_wrapper ${data.items && data.items.four ? 'has-four' : ''}`} style={data.items ? styleToObjResponsive(data.items.style, data.items.style_desktop, desktop) : empty}>
        <div className="HorizontalBlock">
          <div className="HBlockContainer">
            <div className="HBlockContent">
              {data.item_1_subtext && stxt1 && <p className="HBlockSubText" style={styleToObjResponsive(data.item_1_subtext.style, data.item_1_subtext.style_desktop, desktop)} >{stxt1}</p>}
              {data.item_1_logo && data.item_1_logo.image && <img src={data.item_1_logo.image} alt="" className="HBlockLogo" style={styleToObjResponsive(data.item_1_logo.style, data.item_1_logo.style_desktop, desktop)} />}
              <h2 className="HBlockTitle" style={styleToObjResponsive(data.item_1_title.style, data.item_1_title.style_desktop, desktop)}>{getText(data, `item_1_title.text.${lang}`, "")}</h2>
              <p className="HBlockText rich-text" style={styleToObjResponsive(data.item_1_text.style, data.item_1_text.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `item_1_text.text.${lang}`, "") }}></p>
              {data.cta_button.enabled && <a href={data.cta_button.url} rel="noopener noreferrer" className="button gradient" style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)}>
                {getText(data, `cta_button.text.${lang}`, "")}
              </a>}
            </div>
          </div>
        </div>
        <div className="HorizontalBlock">
          <div className="HBlockContainer">
            <div className="HBlockContent">
              {data.item_2_subtext && stxt2 && <p className="HBlockSubText" style={styleToObjResponsive(data.item_2_subtext.style, data.item_2_subtext.style_desktop, desktop)} >{stxt2}</p>}
              {data.item_2_logo && data.item_2_logo.image && <img src={data.item_2_logo.image} alt="" className="HBlockLogo" style={styleToObjResponsive(data.item_2_logo.style, data.item_2_logo.style_desktop, desktop)} />}
              <h2 className="HBlockTitle" style={styleToObjResponsive(data.item_2_title.style, data.item_2_title.style_desktop, desktop)}>{getText(data, `item_2_title.text.${lang}`, "")}</h2>
              <p className="HBlockText rich-text" style={styleToObjResponsive(data.item_2_text.style, data.item_2_text.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `item_2_text.text.${lang}`, "") }}></p>
            </div>
          </div>
        </div>
        <div className="HorizontalBlock">
          <div className="HBlockContainer">
            <div className="HBlockContent">
              {data.item_3_subtext && stxt3 && <p className="HBlockSubText" style={styleToObjResponsive(data.item_3_subtext.style, data.item_3_subtext.style_desktop, desktop)} >{stxt3}</p>}
              {data.item_3_logo && data.item_3_logo.image && <img src={data.item_3_logo.image} alt="" className="HBlockLogo" style={styleToObjResponsive(data.item_3_logo.style, data.item_3_logo.style_desktop, desktop)} />}
              <h2 className="HBlockTitle" style={styleToObjResponsive(data.item_3_title.style, data.item_3_title.style_desktop, desktop)}>{getText(data, `item_3_title.text.${lang}`, "")}</h2>
              <p className="HBlockText rich-text" style={styleToObjResponsive(data.item_3_text.style, data.item_3_text.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `item_3_text.text.${lang}`, "") }}></p>
            </div>
          </div>
        </div>

        {data.items && data.items.four && <div className="HorizontalBlock">
          <div className="HBlockContainer">
            <div className="HBlockContent">
              {data.item_4_subtext && stxt4 && <p className="HBlockSubText rich-text" style={styleToObjResponsive(data.item_4_subtext.style, data.item_4_subtext.style_desktop, desktop)} >{stxt4}</p>}
              {data.item_4_logo && data.item_4_logo.image && <img src={data.item_4_logo.image} alt="" className="HBlockLogo" style={styleToObjResponsive(data.item_4_logo.style, data.item_4_logo.style_desktop, desktop)} />}
              {data.item_4_title && <h2 className="HBlockTitle" style={styleToObjResponsive(data.item_4_title.style, data.item_4_title.style_desktop, desktop)}>{getText(data, `item_4_title.text.${lang}`, "")}</h2>}
              {data.item_4_text && <p className="HBlockText rich-text" style={styleToObjResponsive(data.item_4_text.style, data.item_4_text.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `item_4_text.text.${lang}`, "") }}></p>}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}

export default RowBlocksComponent;