import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from "react-router-dom";
import history from "./history";
import createSagaMiddleware from "redux-saga";
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { setStore as lottoSetStore } from "./store";
import { lpRootReducer } from "./store/reducers";
import { lpSaga } from "./store/sagas";


const asyncDispatchMiddleware = store => next => action => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach(a => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

  const res = next(actionWithAsyncDispatch);
  syncActivityFinished = true;
  flushQueue();

  return res;
};

const sagaMiddleware = createSagaMiddleware();
/*
const store = createStore(
  rootReducer,
  applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)
);
*/
const store = configureStore({
  reducer: {
    lp: lpRootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // we need this because bets config has function definition for bonnus rules in action data
    }).concat(asyncDispatchMiddleware, sagaMiddleware),
});

lottoSetStore(store);
window._store = store;

sagaMiddleware.run(lpSaga);

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
